const compress = (fileObj, callback) => {
  try {
    const image = new Image()
    image.src = URL.createObjectURL(fileObj)
    image.onload = function () {
      const that = this
      // 默认按比例压缩
      let w = that.width
      let h = that.height
      const scale = w / h
      w = fileObj.width || w
      h = fileObj.height || (w / scale)
      let quality = 0.7 // 默认图片质量为0.7
      // 生成canvas
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      // 创建属性节点
      const anw = document.createAttribute('width')
      anw.nodeValue = w
      const anh = document.createAttribute('height')
      anh.nodeValue = h
      canvas.setAttributeNode(anw)
      canvas.setAttributeNode(anh)
      ctx.drawImage(that, 0, 0, w, h)
      // 图像质量
      if (fileObj.quality && fileObj.quality <= 1 && fileObj.quality > 0) {
        quality = fileObj.quality
      }
      // quality值越小，所绘制出的图像越模糊
      const data = canvas.toDataURL('image/jpeg', quality)
      // 压缩完成执行回调
      const newFile = convertBase64UrlToBlob(data)
      callback(newFile)
    }
  } catch (e) {
    console.log('压缩失败!')
  }
}
const convertFile = (file, size, callback) => { // 循环压缩图片，直到满足条件
  if ((file.size / 1024 / 1024) > size) {
    compress(file, function (val) { // 调用图片压缩方法
      convertFile(val, size, callback)
    })
  } else {
    callback(file)
  }
}
const each = (elements, callback) => {
  var i, key
  if (likeArray(elements)) {
    for (i = 0; i < elements.length; i++) { if (callback.call(elements[i], i, elements[i]) === false) return elements }
  } else {
    for (key in elements) { if (callback.call(elements[key], key, elements[key]) === false) return elements }
  }
  return elements
}
function convertBase64UrlToBlob (urlData) {
  const bytes = window.atob(urlData.split(',')[1]) // 去掉url的头，并转换为byte
  // 处理异常,将ascii码小于0的转换为大于0
  const ab = new ArrayBuffer(bytes.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i)
  }
  return new Blob([ab], { type: 'image/png' })
}
function likeArray (obj) {
  var length = !!obj && 'length' in obj && obj.length
  var type = obj.type

  return type !== 'function' && !isWindow(obj) && (
    type === 'array' || length === 0 ||
      (typeof length === 'number' && length > 0 && (length - 1) in obj)
  )
}
function isWindow (obj) {
  return obj != null && obj === obj.window
}

// 是否微信中打开
function isWeChat () {
  var ua = navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i) + '' === 'micromessenger') {
    return true
  } else {
    return false
  }
}

export default function (Vue) {
  // 'wx765f243ceb04633e'// 正式
  // 'wx8b69a2e4a785713d'// 测试
  Vue.prototype.$appid = 'wx765f243ceb04633e'
  // 添加全局API
  Vue.prototype.$tool = {
    compress,
    convertFile,
    each,
    isWeChat
  }
}
